import { RCM_BLOCK_TYPE } from 'common/hooks/useRcm';
import Banners from 'config/constants/banner/banners';
import { PuidsType } from 'config/constants/common';
import {
  COMMENTS_APIS,
  COOLSTREAM_APIS,
  HOROSCOPES_APIS,
  ID_APIS,
  PEROXIDE_APIS,
  RAMBLER_APIS,
  RECOMMEND_APIS,
  RECOMMEND_PLUS_APIS,
  SSP_ADBLOCK_APIS,
  TRAVEL_APIS,
  URANYL_APIS,
  WINLINE_APIS,
} from 'config/constants/configApis';
import { APP_TYPE } from 'config/constants/devices';
import {
  NEWS_PROJECT_ALIASES,
  PROJECT_ALIASES,
  PROJECT_IDS,
} from 'config/constants/projects/constants';
import { SPLITS } from 'config/constants/splits';

/**
 * Перечисление всех наших используемых api.
 */
export enum API_NAMES {
  // Наш основной API, возвращает инфу о кластерах, авторах и прочем
  Peroxide = 'peroxide',
  // Дополнительный API, возвращает инфу о курсах валют и графиках
  Uranyl = 'uranyl',
  // Старый основной API, нужен только для получения инфы о горячей новости
  Coolstream = 'coolstream',
  // Дополнительный API проекта Рамблер/Главная, возвращает инфу для виджетов
  Rambler = 'rambler',
  // Дополнительный API проекта Рамблер/Id, возвращает информацию о залогиненном пользователе
  Id = 'id',
  // Второй основной API, возвращает новости, рекомендованные для текущего пользователя
  Recommend = 'recommend',
  // Дополнительный API, возвращает новости источника кластера
  RecommendPlus = 'recommendPlus',
  // Дополнительный API проекта Рамблер/Комментарии, возвращает инфу о комментариях
  Comments = 'comments',
  // Дополнительный старый API, возвращал информацию о матчах
  Winline = 'winline',
  // Дополнительный технический API, возвращает скрипты для вставки антиадблока в страницу
  SSPAdblock = 'sspAdblock',
  // Дополнительный API проекта Гороскопы
  Horoscopes = 'horoscopes',
  // Дополнительный API прокси кулстрима для вертикали путешествий NEWS-11816
  Travel = 'travel',
}

/**
 * Объект данных о баннере из файлов конфига
 */
export type BannersMapType = {
  [ProjectAlias in NEWS_PROJECT_ALIASES]: {
    [APP_TYPE.mobile]: BannersType;
    [APP_TYPE.desktop]: BannersType;
  };
};

/**
 * Тип стенда приложения
 */
export type RunProfileType =
  | 'local'
  | 'stage:test'
  | 'stage:prod'
  | 'stage1:test'
  | 'stage1:prod'
  | 'stage2:test'
  | 'stage2:prod'
  | 'stage3:test'
  | 'stage3:prod'
  | 'stage4:test'
  | 'stage4:prod'
  | 'stage5:test'
  | 'stage5:prod'
  | 'stage6:test'
  | 'stage6:prod'
  | 'extestm:test'
  | 'extestm:prod'
  | 'release';

export type RecommendBlockIDType = {
  [key in RCM_BLOCK_TYPE]: RecommendType;
};

export type RecommendType = {
  [alias in NEWS_PROJECT_ALIASES]: string;
};

export type CommercialS2SConfig = {
  mobile: { [id: number]: CommercialClusterS2S };
  desktop: { [id: number]: CommercialClusterS2S };
};

export type HeadAdminDoodleDataType = {
  id: number;
  url: string;
  title: string;
  imageDesktop: string;
  imageMobile: string;
};

export type HeadAdminConfig = {
  settings: { [key: string]: boolean };
  doodles: { [key in NEWS_PROJECT_ALIASES]?: HeadAdminDoodleDataType };
  splits: SPLITS;
  tumblers: Partial<{
    gigaEnabled: boolean;
    promoWidgetEnabled: boolean;
    promoWidgetPlaceholderEnabled: boolean;
  }>;
  generatedAt: string | number;
  variables: { [key: string]: string };
};

/**
 * Тип конфигов для кэшей.
 * @field ALL - флаг, включающий все виды кэширования разом. Если true - остальные настройки игнорируются.
 * @field SERVICE_WORKER_CACHE - флаг, управляющий кэшированием сервис-воркером. Позволяет сохранять страницы.
 * @field MEMORY_CACHE - флаг, разрешающий использовать @see ApiCache для кэширования в нем данных.
 * @field REDIS_CACHE - флаг, разрешающий использовать внешний редис
 *  для кэширования запросов и отдачи их вместо основного бекенда.
 * @field FALLBACK_CACHE - флаг, разрешающий использовать внешний редис
 *  для кэширования запросов для использования в те моменты, когда сервер отвечает с ошибкой.
 */
export type CacheConfigType = {
  ALL: boolean;
  SERVICE_WORKER_CACHE?: boolean;
  MEMORY_CACHE?: boolean;
  REDIS_CACHE?: boolean;
  FALLBACK_CACHE?: boolean;
};

/**
 * Конфигурация приложения
 * @param APP_NAME - имя приложения
 * @param RUN_PROFILE - тип стенда приложения
 * @param VERSION - версия приложения
 * @param PORT - порт запуска
 * @param STATS - данные о чанках из build/loadable-stats.json или {}
 * @param CACHE - флаг вкл/выкл кэширования
 * @param LOGGING - флаг вкл/выкл логирования приложения
 * @param LOGLEVEL - уровень обратки ошибок для Sentry
 * @param REDUX_LOGGER - вкл/выкл redux-логирование в консоль
 * @param API_URL - Наш основной API, возвращает инфу о кластерах, авторах и прочем
 * @param URANYL_URL - Дополнительный API, возвращает инфу о курсах валют и графиках
 * @param COOLSTREAM_URL - Старый основной API, нужен только для получения инфы о горячей новости
 * @param RAMBLER_URL - Дополнительный API проекта Рамблер/Главная, возвращает инфу для виджетов
 * @param ID_URL - Дополнительный API проекта Рамблер/Id, возвращает информацию о залогиненном пользователе
 * @param RECOMMEND_URL - Второй основной API, возвращает новости, рекомендованные для текущего пользователя
 * @param RECOMMEND_PLUS_URL - Дополнительный API, возвращает новости источника кластера
 * @param COMMENTS_URL - Дополнительный API проекта Рамблер/Комментарии, возвращает инфу о комментариях
 * @param WINLINE_URL - Дополнительный старый API, возвращал информацию о матчах
 * @param SSP_ADBLOCK_URL - Дополнительный технический API, возвращает скрипты для вставки антиадблока в страницу
 * @param HOROSCOPES_URL - Дополнительный API проекта Гороскопы
 * @param TRAVEL_URL - Дополнительный API для вертикали travel прокси кулстрима
 * @param API_TIMEOUT - общий таймаут запросов, по истечению которого возвращается объект ошибки
 * @param API_TIMEOUT_CLIENT - таймаут запросов на клиенте
 * @param API_KEEP_ALIVE_TIMEOUT - Header Keep-Alive для запросов в peroxide, время действия соединения
 * @param API_KEEP_ALIVE_MAX - Header Keep-Alive для запросов в peroxide, колво действующих соединений
 * @param METRICS - флаг вкл/выкл сбора метрик
 * @param SENTRY_DSN - урл, по которому будут слаться сообщения об ошибках
 * @param SENTRY_ENABLED_SERVER - флаг вкл/выкл Sentry на сервере
 * @param SENTRY_ENABLED_BROWSER - флаг вкл/выкл Setnry в браузере
 * @param SENTRY_SAMPLE_RATE_SERVER - как часто отправлять запрос в сентри на сервере
 * @param SENTRY_TRACES_SAMPLE_RATE_SERVER - как часто собираются события ДО возникновения ошибок
 * @param SENTRY_SAMPLE_RATE_BROWSER - как часто отправлять запрос в сентри в браузере
 * @param SENTRY_TRACES_SAMPLE_RATE_BROWSER - как часто собираются события ДО возникновения ошибок
 * @param IS_REDIS_EMULATION_ENABLED - флаг, что включена эмуляция редиса.
 *   Если флаг установлен в true, то необходимо разворачивать локально редис.
 *   NOTE: Редис не может быть запущен в Windows.
 * @param PROJECTS_DOMAINS - объект связей id-alias проектов
 * @param PROJECTS - данные о всех проектах по ID и Alias
 * @param BANNERS - данные для загрузки баннеров
 * @param TOPICS - данные о всех топиках по ID проекта(вертикали)
 * @param REDIS - конфигурация для REDIS-бд для кэшей (неактивен)
 * @param PUSHWOOSH - конфигурация для PUSHWOOSH-нотификаций в service worker
 * @param ANTI_ADBLOCK - скрипты ANTI_ADBLOCK, сохраненные по alias вертикалей
 * @param SETTINGS - конфигурация проекта (сплиты, вкл/выкл antiAdblock и др.)
 * @param SPLITS - принудительная конфигурация сплитов (из settings-local)
 * @param STATIC_PUBLIC_PATH - переназначение домена отдачи статики. Нужно для dev при мобильной отладки
 */
export type Config = {
  APP_NAME: string;
  RUN_PROFILE: RunProfileType;
  VERSION: string;
  PORT?: number | string;
  LOGGING?: boolean;
  LOGLEVEL?: 'ERROR' | '';
  REDUX_LOGGER?: boolean;
  NODE_CACHE_LOGGER?: boolean;
  METRICS?: boolean;
  ENABLE_CLIENT_METRICS?: boolean;
  SENTRY_DSN?: string;
  SENTRY_ENABLED_SERVER?: boolean;
  // Частота сбора ошибок
  SENTRY_SAMPLE_RATE_SERVER?: number;
  // Частота сбора событий ДО ошибки
  SENTRY_TRACES_SAMPLE_RATE_SERVER?: number;
  SENTRY_ENABLED_BROWSER?: boolean;
  // Частота сбора ошибок
  SENTRY_SAMPLE_RATE_BROWSER?: number;
  // Частота сбора событий ДО ошибки (может сломать прод, аккуратней)
  SENTRY_TRACES_SAMPLE_RATE_BROWSER?: number;
  IS_REDIS_EMULATION_ENABLED?: boolean;
  API_URL?: PEROXIDE_APIS;
  URANYL_URL?: URANYL_APIS;
  COOLSTREAM_URL?: COOLSTREAM_APIS;
  RAMBLER_URL?: RAMBLER_APIS;
  ID_URL?: ID_APIS;
  WINLINE_URL?: WINLINE_APIS;
  RECOMMEND_URL?: RECOMMEND_APIS;
  RECOMMEND_PLUS_URL?: RECOMMEND_PLUS_APIS;
  COMMENTS_URL?: COMMENTS_APIS;
  SSP_ADBLOCK_URL?: SSP_ADBLOCK_APIS;
  HOROSCOPES_URL?: HOROSCOPES_APIS;
  TRAVEL_URL?: TRAVEL_APIS;
  API_KEEP_ALIVE_TIMEOUT?: number;
  API_KEEP_ALIVE_MAX?: number;
  API_TIMEOUT?: number;
  API_TIMEOUT_CLIENT?: number;
  PROJECTS_DOMAINS?: {
    [projectName in PROJECT_ALIASES]: string;
  };
  PROJECTS?: {
    byID?: {
      [projectID: number]: ProjectType;
    };
    byAlias?: {
      [projectName in PROJECT_ALIASES]?: ProjectType;
    };
  };
  TOPICS?: {
    list?: ATTopic[];
    byProjectID?: {
      [projectID in PROJECT_IDS]?: ATTopic[];
    };
  };
  BANNERS: BannersMapType;
  REDIS?: {
    CLUSTER?: import('ioredis').ClusterNode[];
    OPTIONS?: import('ioredis').RedisOptions;
  };
  CACHE?: CacheConfigType;
  PUSHWOOSH?: {
    [id in PROJECT_IDS]: {
      app_code: string;
    };
  };
  STATS_FILE?: string;
  EDITORS?: {
    byProjectID: {
      [id: number]: FullEditorType[];
    };
    all: FullEditorType[];
  };
  ANTI_ADBLOCK?: {
    [projectAlias: string]: string;
  };
  SETTINGS?: {
    /** Флаг вкл/выкл antiAdblock */
    antiAdblock: boolean;
  };
  RECOMMEND_BLOCK_ID?: RecommendBlockIDType;
  RECOMMEND_RCM_ID?: string;
  SPLITS?: { [splitAlias: string]: SplitConfigType['alias'] };
  COMMERCIAL_S2S?: CommercialS2SConfig;
  STATIC_PUBLIC_PATH?: string;
  RUID_MOCK?: string;
};

/**
 * Объект конфигурации баннера
 * @param begun - ID баннера
 * @param data - дополнительная конфигурация баннера
 * @param clusterFeedReadAlso - дополнительная конфигурация баннера
 */
export type BannerType = {
  begun: number;
  ampBegun?: number;
  display?: string;
  p1?: string;
  data?: PuidsType;
  clusterFeedReadAlso?: PuidsType;
  dir1?: PuidsType;
};

/**
 * Данные для баннеров по вертикали и desktop/mobile
 * @param BEGUN_PAD_ID - главный ID для баннеров (начальный)
 * @param banners - конфигурация кодов баннеров для десктопа и мобилки
 */
export type BannersType = {
  BEGUN_PAD_ID: number;
  banners?: {
    [x in Banners]?: BannerType;
  };
};

/**
 * Тип данных для баннеров для мобильной и десктопной версий.
 */
export type BannersDataType = {
  [APP_TYPE.desktop]: BannersType;
  [APP_TYPE.mobile]: BannersType;
};
