import React, { useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { SentryRoute } from 'common/components/SentryRoute';
import { selectIsMobile } from 'common/redux/runtime/selectors';
import { IAppRoute } from 'typings/AppRoute';

/**
 * Вспомогательный хук, генерирующий список роутов для
 *  приложения.
 * @param getRoutes - функция для получения списка роутов на основании Runtime.
 */
export const useRoutes = (
  getRoutes: (isMobile: Runtime['settings']['isMobile']) => IAppRoute<{}>[],
) => {
  const isMobile = useSelector(selectIsMobile, shallowEqual);

  const routes = useMemo(
    () => getRoutes(isMobile),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return useMemo(
    () =>
      routes.map((route: IAppRoute) => (
        <SentryRoute {...route} key={route.name} />
      )),
    [routes],
  );
};
