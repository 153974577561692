import { autotagRoutes } from 'common/routes/autotag';
import { autotagTopRoutes } from 'common/routes/autotagTop';
import { banksRoutes } from 'common/routes/banks';
import { clusterRoutes } from 'common/routes/cluster';
import { clusterCommentsRoutes } from 'common/routes/clusterComments';
import { clusterItemsRoutes } from 'common/routes/clusterItems';
import { consensusRoutes } from 'common/routes/consensus';
import { converterRoutes } from 'common/routes/converter';
import { crossRoutes } from 'common/routes/cross';
import { currenciesRoutes } from 'common/routes/currencies';
import { currencyRoutes } from 'common/routes/currency';
import { dateRoutes } from 'common/routes/date';
import { editorRoutes } from 'common/routes/editor';
import { editorsRoutes } from 'common/routes/editors';
import { exchangeRoutes } from 'common/routes/exchange';
import { expertRoutes } from 'common/routes/expert';
import { guideRoutes } from 'common/routes/guide';
import { homeRoutes } from 'common/routes/home';
import { latestRoutes } from 'common/routes/latest';
import { notFoundRoutes } from 'common/routes/notFound';
import { regionRoutes } from 'common/routes/region';
import { tagRoutes } from 'common/routes/tag';
import { themeRoutes } from 'common/routes/theme';
import { topicRoutes } from 'common/routes/topic';
import { AUTOTAG_TYPE } from 'config/constants/cluster';
import { IAppRoute } from 'typings/AppRoute';

/**
 * Получение массива роутов
 *
 * Важно! Роуты с наиболее обобщеным path должны идти ниже чем с каким то конкретным
 * Например topicRoutes должны идти ниже чем editorsRoutes, latestRoutes и пр
 * тк /editors/ и /latest/ подходят под path: '/:topicAlias/'
 * а внутри reactRouterMatching берется тот роут, который подошел первым
 *
 * Поэтому topicRoutes и regionRoutes находятся в самом низу
 * @param runtime – объект конфигурации о проекте
 */
export const getCommonRoutes = (
  isMobile: Runtime['settings']['isMobile'],
): IAppRoute[] => [
  homeRoutes(isMobile),
  exchangeRoutes(isMobile),
  crossRoutes(isMobile) as IAppRoute<{}>,
  converterRoutes(isMobile), // должен быть выше clusterRoutes иначе определяется как кластер изза наличия calculators/converter в topicsList
  consensusRoutes(isMobile) as IAppRoute<{}>,
  currenciesRoutes(isMobile),
  currencyRoutes(isMobile) as IAppRoute<{}>,
  banksRoutes(isMobile) as IAppRoute<{}>,
  clusterRoutes(isMobile) as IAppRoute<{}>,
  clusterCommentsRoutes(isMobile) as IAppRoute<{}>,
  editorRoutes(isMobile) as IAppRoute<{}>,
  editorsRoutes(isMobile),
  autotagTopRoutes(isMobile, AUTOTAG_TYPE.person), // должны быть выше autotagRoutes, чтобы правильно матчить роутер персон
  autotagTopRoutes(isMobile, AUTOTAG_TYPE.organization),
  autotagRoutes(isMobile) as IAppRoute<{}>,
  expertRoutes(isMobile),
  guideRoutes(isMobile),
  themeRoutes(isMobile) as IAppRoute<{}>,
  clusterItemsRoutes(isMobile) as IAppRoute<{}>,
  latestRoutes(isMobile),
  tagRoutes(isMobile) as IAppRoute<{}>,
  dateRoutes(isMobile) as IAppRoute<{}>,
  topicRoutes(isMobile) as IAppRoute<{}>, // должны идти перед регионами
  regionRoutes(isMobile) as IAppRoute<{}>, // должны идти после latest чтобы /latest не открывались как регион
  notFoundRoutes(isMobile),
];
