import loadable from '@loadable/component';
import React from 'react';

import { fetchCurrencyRatesFromPeriod } from 'common/redux/commonData/currencies';
import { selectCurrencyIdByCharCode } from 'common/redux/commonData/currencies/selectors';
import { fetchBanksRates } from 'common/redux/commonData/widgets/banksRatesWidget/asyncs';
import {
  BANKS_RATES_CURRENCY_ACTION_BUY,
  BANKS_RATES_CURRENCY_ACTION_SELL,
} from 'common/redux/commonData/widgets/banksRatesWidget/typings';
import {
  setConsensusCurrency,
  setConsensusForecaster,
} from 'common/redux/commonData/widgets/consensusWidget';
import { fetchForecastsLast } from 'common/redux/commonData/widgets/consensusWidget/asyncs';
import {
  CONSENSUS_CURRENCIES,
  DEFAULT_CONSENSUS_CURRENCY,
} from 'common/redux/commonData/widgets/consensusWidget/constants';
import { selectWidgetsConsensusForecasts } from 'common/redux/commonData/widgets/consensusWidget/selectors';
import { FORECASTS_TYPE } from 'common/redux/commonData/widgets/consensusWidget/typings';
import { setActiveTab } from 'common/redux/commonData/widgets/tabsWidget';
import { TABS_TYPE } from 'common/redux/commonData/widgets/tabsWidget/typings';
import { setConsensusPuids } from 'common/redux/pages/consensus';
import {
  setFirstCurrencyConverter,
  setSecondCurrencyConverter,
} from 'common/redux/pages/converter';
import { setCurrentCurrencyId } from 'common/redux/pages/currency';
import { CURRENCY_CHAR_CODE, PERIODS } from 'config/constants/finance';
import { PAGE_TYPE } from 'config/constants/routerName';
import { IAppRoute, PromiseListType } from 'typings/AppRoute';

import { getFinancePromiseList } from '../getFinancePromiseList';
import { appendPageDownloader } from '../pageLoadable';

const ConsensusDesktop = loadable(() => import('desktop/pages/Consensus'));
const ConsensusMobile = loadable(() => import('mobile/pages/Consensus'));

type ConsensusMatchRoute = {
  charCode: CURRENCY_CHAR_CODE;
};

/**
 * Роут страницы прогноза курса.
 * https://finance.rambler.ru/currencies/consensus/
 * https://finance.rambler.ru/currencies/consensus/USD/
 * @see https://www.figma.com/file/vboo3WIcMAD7qk7BgafOEl/Finance?type=design&node-id=2331-31536&mode=design&t=ivowiGOuNDqTiyb2-4
 */
export const consensusRoutes = (
  isMobile: Runtime['settings']['isMobile'],
): IAppRoute<ConsensusMatchRoute> => ({
  name: PAGE_TYPE.consensus,
  exact: true,
  path: `/currencies/consensus/:charCode(${CONSENSUS_CURRENCIES.join('|')})?/`,
  render: isMobile ? () => <ConsensusMobile /> : () => <ConsensusDesktop />,
  fetchData: async ({ dispatch, getState }, { params: { charCode } }) => {
    dispatch(setConsensusPuids(getState()));

    const promiseList: PromiseListType = getFinancePromiseList(dispatch);

    const currencyCharCode = charCode ?? DEFAULT_CONSENSUS_CURRENCY;

    promiseList.push(
      dispatch(
        fetchForecastsLast({
          charCode: currencyCharCode,
          type: FORECASTS_TYPE.week,
        }),
      ),
      dispatch(
        fetchForecastsLast({
          charCode: currencyCharCode,
          type: FORECASTS_TYPE.month,
        }),
      ),
      dispatch(
        fetchForecastsLast({
          charCode: currencyCharCode,
          type: FORECASTS_TYPE.year,
        }),
      ),
      // Для виджета валюты
      dispatch(
        fetchCurrencyRatesFromPeriod({
          charCode: currencyCharCode,
          period: PERIODS.week,
        }),
      ),
      // Для виджет курса обмена
      dispatch(
        fetchBanksRates({
          charCode: CURRENCY_CHAR_CODE.USD,
          sort: BANKS_RATES_CURRENCY_ACTION_BUY.USD,
        }),
      ),
      dispatch(
        fetchBanksRates({
          charCode: CURRENCY_CHAR_CODE.USD,
          sort: BANKS_RATES_CURRENCY_ACTION_SELL.USD,
        }),
      ),
    );

    await Promise.all(
      appendPageDownloader({
        promiseList,
        Component: isMobile ? ConsensusMobile : ConsensusDesktop,
        key: Object.keys({ ConsensusDesktop })[0],
      }),
    );

    dispatch(setActiveTab(TABS_TYPE.forecast));

    const currencyId = selectCurrencyIdByCharCode(currencyCharCode)(getState());

    // Для виджета валюты
    dispatch(setCurrentCurrencyId({ currencyId }));

    const consensusForecasts = selectWidgetsConsensusForecasts(getState());

    const consensusForecaster =
      consensusForecasts[FORECASTS_TYPE.week].forecaster?.name;

    dispatch(setConsensusCurrency(currencyCharCode));
    dispatch(setConsensusForecaster(consensusForecaster));

    const currenciesConverter =
      charCode === undefined
        ? {
            firstCharCode: CURRENCY_CHAR_CODE.USD,
            secondCharCode: CURRENCY_CHAR_CODE.RUB,
          }
        : {
            firstCharCode: charCode,
            secondCharCode: CURRENCY_CHAR_CODE.RUB,
          };

    // Для конвертера валют
    dispatch(setFirstCurrencyConverter(currenciesConverter.firstCharCode));
    dispatch(setSecondCurrencyConverter(currenciesConverter.secondCharCode));
  },
});
