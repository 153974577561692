import {
  browserTracingIntegration,
  captureException,
  getCurrentScope,
  init,
  reactRouterV5BrowserTracingIntegration,
  withScope,
} from '@sentry/react';
import Cookies from 'js-cookie';

import { UserType, sendToSentry } from './index';

/**
 * Инициализирует Sentry в браузере, некоторыми runtime.
 */
export const initSentryClient = (runtime: Runtime) => {
  const {
    settings: { isBot, isMobile },
    sentry: { dsn, enabled, sampleRate, tracesSampleRate },
    profile,
  } = runtime;

  if (!enabled) return;

  const ruid = Cookies.get('ruid');
  const user: UserType = {};

  if (ruid) {
    user.id = ruid;
  }

  init({
    dsn,
    sampleRate,
    release: __SENTRY_RELEASE__,
    environment: profile,
    tracesSampleRate,
    normalizeDepth: 5, // Пробное значение глубины. Можно изменять
    /**
     * Взято из
     * @see https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/?original_referrer=https%3A%2F%2Fyandex.ru%2F#react-router-v4v5
     */
    integrations: [
      browserTracingIntegration(),
      reactRouterV5BrowserTracingIntegration({ history: window.history }),
    ],
    // TODO(NEWS-0000): filter capirs
    // beforeSend(event, hint) {
    //   return event;
    // },
  });

  getCurrentScope().setUser(user);
  getCurrentScope().setTag('app', 'client');
  getCurrentScope().setTag('user_agent', window.navigator.userAgent);
  getCurrentScope().setTag('is_mobile', String(isMobile));
  getCurrentScope().setTag('is_bot', String(isBot));
  getCurrentScope().setTag('viewport', String(window.innerWidth));
  // TODO(NEWS-0000): AntiAdblock, Adblock
};

/**
 * Функция отправки ошибки с браузера с настраиваемым scope.
 * @param props.withScope - callback для конфигурации;
 * @param props.captureException - функция отправки сообщения.
 */
export const sentryClientSend = sendToSentry({ withScope, captureException });
