import parseLinkHeader from 'parse-link-header';

import { metricsBatch } from 'server/collectors/prometheus/utils/metricsBatch';
import { COUNTERS_NAMES } from 'server/typings';
import { API_NAMES } from 'typings/Config';

import { getData } from './getData';
import { GetDataType, RequestError } from './getData/types';
import { addParamsInPathRecommended } from './requestHandler/addParamsInPathRecommended';

const MIN_REQUEST_COUNTER = 1;
const MIN_REQUEST_REPEAT_COUNTER = 0;
const MAX_REQUEST_REPEAT_COUNTER = 3;

/**
 * Получение заголовка link и данные из него
 * @param res - объект результата запроса
 */
export const getNextPage = (res: APIResponse) => {
  const { headers } = res;

  if (!headers) return '';

  const getHeaderLink = () => {
    if (__SERVER__) {
      return headers._headers?.link?.[0] || '';
    }

    return headers.get('link') ?? '';
  };

  return parseLinkHeader(getHeaderLink())?.next?.page ?? '';
};

/**
 * Функция получения юзер агента как на стороне сервера (вытаскивается из хедера), так и на клиенте.
 */
export const getUserAgentStr = () => {
  if (__SERVER__) {
    // @ts-expect-error: ¯\_(ツ)_/¯
    return global?.userAgent
      ? // @ts-expect-error: ¯\_(ツ)_/¯
        `&userAgent=${encodeURIComponent(global.userAgent)}`
      : '';
  }

  return window?.navigator?.userAgent
    ? `&userAgent=${encodeURIComponent(window.navigator.userAgent)}`
    : '';
};

/**
 * Функция получения данных из рекомендов с добавлением дополнительных параметров на стороне клиента.
 * @param requestParams - параметры запроса данных.
 */
export const getRCMData = async (requestParams: GetDataType) => {
  if (__SERVER__) {
    const { api, path, adtechData } = requestParams;

    const url = `${api.url}${path}`;

    const serverUrl = new URL(url);

    if (adtechData?.uid && adtechData?.scope) {
      serverUrl.searchParams.append('adtech_uid', adtechData.uid);
      serverUrl.searchParams.append('adtech_uid_scope', adtechData.scope);
    }

    const serverPath = `${serverUrl.pathname}${serverUrl.search}`;

    return getData<APIRecommender>({ ...requestParams, path: serverPath });
  }

  // добавление дополнительных параметров из топ100 к запросам на клиенте
  // https://jira.rambler-co.ru/browse/NEWS-10459
  const clientPath = await addParamsInPathRecommended(requestParams.path);

  return getData<APIRecommender>({ ...requestParams, path: clientPath });
};

/**
 * Функция преобразования списка конфигов к полноценному объекту api,
 * который требуется для совершения запроса.
 * @param props - параметры функции;
 * @param props.apiConfig - объект с данными о конфигах;
 * @param props.apiName - имя api, данные о котором надо получить;
 * @param props.timeout - опциональное поле для перезаписи таймаута запроса на стороне сервера;
 * @param props.clientTimeout - опциональное поле для перезаписи таймаута запроса на стороне клиента;
 */
export const getAPIFromConfig = ({
  apiConfig,
  apiName,
  timeout,
  clientTimeout,
}: {
  apiConfig: ApiConfigType;
  apiName: API_NAMES;
  timeout?: number;
  clientTimeout?: number;
}): Api => {
  const api = apiConfig[apiName];
  const defaultAPI = apiConfig.default;

  if (!api)
    return (
      defaultAPI ??
      ({
        clientTimeout: clientTimeout ?? 0,
        timeout: timeout ?? 0,
        url: '',
      } as Api)
    );

  if (typeof api === 'string') {
    return {
      url: api,
      timeout: timeout ?? defaultAPI.timeout,
      clientTimeout: clientTimeout ?? defaultAPI.clientTimeout,
    };
  }

  return {
    url: api.url,
    clientTimeout:
      clientTimeout ?? api.clientTimeout ?? defaultAPI.clientTimeout,
    timeout: timeout ?? api.timeout ?? defaultAPI.timeout,
    keepAlive: api.keepAlive ?? undefined,
  };
};

type SendRequestErrorPropsType = {
  requestUrl: string;
  requestError: RequestError;
};

/**
 * Функция отправки ошибки в sentry и prometheus.
 * @param props.requestUrl - url запроса;
 * @param props.requestError - ошибка запроса.
 */
export const sendRequestError = ({
  requestError,
}: SendRequestErrorPropsType) => {
  // Batch ошибки запроса
  metricsBatch.pushToCounters<COUNTERS_NAMES.RequestError>({
    counterName: COUNTERS_NAMES.RequestError,
    params: {
      errorType: requestError.name,
      entrypoint: requestError.entrypoint,
      status: `${requestError.status}`,
    },
  });
};

type RefetchFailedRequestType = (
  requestCallback: () => Promise<any>,
  requestCounter?: number,
) => Promise<APIResponse<any, any>>;

/**
 * Обертка для повторных запросов.
 * @param requestCallback - функция запроса;
 * @param requestCounter - счетчик запроса.
 */
export const refetchFailedRequest: RefetchFailedRequestType = async (
  requestCallback,
  requestCounter = 0,
) => {
  const { data, error, entrypoint } = await requestCallback();

  // eslint-disable-next-line no-param-reassign
  requestCounter += 1;

  if (
    (!Array.isArray(data) || !data.length || error) &&
    requestCounter <= MAX_REQUEST_REPEAT_COUNTER
  ) {
    return refetchFailedRequest(requestCallback, requestCounter);
  }

  const counter =
    requestCounter > MIN_REQUEST_COUNTER
      ? requestCounter - MIN_REQUEST_COUNTER
      : MIN_REQUEST_REPEAT_COUNTER;

  // Batch повторяющихся запросов
  metricsBatch.pushToCounters<COUNTERS_NAMES.RepeatRequest>({
    counterName: COUNTERS_NAMES.RepeatRequest,
    params: {
      entrypoint,
      counter: `${counter}`,
    },
  });

  return { data, error };
};
