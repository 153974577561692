// NEWS-11896
export enum HUMAN_CENTERED_STRATEGY_SPLIT_VALUES {
  on = 'on',
  off = 'off',
  default = 'default',
}

export enum SPLITS_NAMES {
  humanCenteredStrategy = 'humanCenteredStrategy',
}

export type SPLITS = Partial<{
  /** Сплит с новым перезапуском */
  [SPLITS_NAMES.humanCenteredStrategy]: HUMAN_CENTERED_STRATEGY_SPLIT_VALUES;
}>;
