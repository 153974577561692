import loadable from '@loadable/component';
import React from 'react';

import { RCM_BLOCK_TYPE } from 'common/hooks/useRcm';
import {
  selectTopicIdByAlias,
  selectTopicsByProjectId,
} from 'common/redux/commonData/topics/selectors';
import { fetchTopicNews } from 'common/redux/commonData/widgets/topicNewsWidget/asyncs';
import {
  LIMIT_BY_CLUSTERS_DESKTOP,
  LIMIT_BY_CLUSTERS_FETCHING,
  LIMIT_BY_CLUSTERS_MOBILE,
} from 'common/redux/pages/constants';
import {
  fetchTopic,
  setTopicInfo,
  setTopicPuids,
} from 'common/redux/pages/topic';
import { fetchFullRecsData } from 'common/redux/pages/topic/asyncs';
import {
  selectTopicPageClusterIds,
  selectTopicPageRecommendClusterIds,
} from 'common/redux/pages/topic/selectors';
import {
  selectProjectAlias,
  selectProjectId,
} from 'common/redux/runtime/selectors';
import {
  appendPageDownloader,
  getPageDownloader,
} from 'common/routes/pageLoadable';
import { getIsFormatTopic } from 'config/constants/common';
import { PAGE_TYPE } from 'config/constants/routerName';
import { IAppRoute, PromiseListType } from 'typings/AppRoute';
import { filterTopics } from 'utils/filterTopics';

import { LIMIT_FOR_FIRST_CLUSTERS_BLOCK } from './constants';
import { loadWidgetData } from './utils';

const TopicDesktop = loadable(() => import('desktop/pages/Topic'));
const TopicMobile = loadable(() => import('mobile/pages/Topic'));

type TopicMatchProps = {
  topicAlias: string;
};

/**
 * Роут рубрики.
 * https://news.rambler.ru/politics/
 */
export const topicRoutes = (
  isMobile: Runtime['settings']['isMobile'],
): IAppRoute<TopicMatchProps> => ({
  name: PAGE_TYPE.topic,
  exact: true,
  sensitive: true,
  path: `/:topicAlias([-_a-z0-9]+)/`,
  render: isMobile ? () => <TopicMobile /> : () => <TopicDesktop />,
  fetchData: async ({ dispatch, getState }, { params: { topicAlias } }) => {
    const projectId = selectProjectId(getState());
    const projectAlias = selectProjectAlias(getState());
    const topicId = selectTopicIdByAlias(topicAlias, projectId)(getState());
    const projectTopics = selectTopicsByProjectId(projectId)(getState());
    const filteredTopics = filterTopics(projectTopics, projectId);

    const isFormatTopic = getIsFormatTopic(topicAlias);

    dispatch(setTopicPuids({ topicAlias, projectAlias }));
    dispatch(setTopicInfo({ topicId, isFormatTopic }));

    await dispatch(
      fetchTopic({
        topicId,
        length: LIMIT_BY_CLUSTERS_FETCHING,
        limitBy: isMobile
          ? LIMIT_BY_CLUSTERS_MOBILE
          : LIMIT_BY_CLUSTERS_DESKTOP,
      }),
    );

    const topicClusterIds = selectTopicPageClusterIds(getState());
    const topicClusterIdsLength = topicClusterIds.length;

    /**
     * Догружаем кластеров из рекоммендов,
     *  если изначально их меньше указанного числа
     */
    if (topicClusterIdsLength < LIMIT_FOR_FIRST_CLUSTERS_BLOCK) {
      await dispatch(
        fetchFullRecsData({
          length: LIMIT_FOR_FIRST_CLUSTERS_BLOCK - topicClusterIdsLength,
          excludedClustersIds: topicClusterIds,
          rcmBlockType: isMobile
            ? RCM_BLOCK_TYPE.topicMobile
            : RCM_BLOCK_TYPE.topicDesktop,
        }),
      );
    }

    const topicRecommendClusterIds =
      selectTopicPageRecommendClusterIds(getState());

    if (isMobile) {
      await getPageDownloader(TopicMobile, Object.keys({ TopicMobile })[0]);
    } else {
      const promiseList: PromiseListType = [
        ...loadWidgetData({
          projectId,
          topicAlias,
          itemExcludedIds: [...topicClusterIds, ...topicRecommendClusterIds],
          rcmBlockType: RCM_BLOCK_TYPE.topNewsWidgetDesktop,
        }).map((promise) => dispatch(promise)),
        dispatch(fetchTopicNews({ topicId: filteredTopics[0].id })),
      ];

      await Promise.all(
        appendPageDownloader({
          promiseList,
          Component: TopicDesktop,
          key: Object.keys({ TopicDesktop })[0],
        }),
      );
    }
  },
});
