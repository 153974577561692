import loadable from '@loadable/component';
import React from 'react';

import { fetchEditorsByProjectId } from 'common/redux/commonData/editors/asyncs';
import { selectEditorsInfo } from 'common/redux/commonData/editors/selectors';
import { setEditorsIds, setEditorsPuids } from 'common/redux/pages/editors';
import {
  selectProjectAlias,
  selectProjectId,
} from 'common/redux/runtime/selectors';
import { getPageDownloader } from 'common/routes/pageLoadable';
import { PAGE_TYPE } from 'config/constants/routerName';
import { IAppRoute } from 'typings/AppRoute';

const EditorsDesktop = loadable(() => import('desktop/pages/Editors'));
const EditorsMobile = loadable(() => import('mobile/pages/Editors'));

/**
 * Роут редакторов/авторов.
 * https://news.rambler.ru/editors/
 */
export const editorsRoutes = (
  isMobile: Runtime['settings']['isMobile'],
): IAppRoute => ({
  name: PAGE_TYPE.editors,
  exact: true,
  path: '/editors/',
  render: isMobile ? () => <EditorsMobile /> : () => <EditorsDesktop />,
  fetchData: async ({ dispatch, getState }) => {
    const projectId = selectProjectId(getState());
    const projectAlias = selectProjectAlias(getState());

    dispatch(setEditorsPuids(projectAlias));

    await dispatch(fetchEditorsByProjectId({ projectId }));

    const editors = selectEditorsInfo(getState());
    const editorsIds = editors
      .filter((editor) => editor?.is_active)
      .map((editor: FullEditorType) => editor.id);

    dispatch(setEditorsIds(editorsIds));

    const pageDownloaderParams = isMobile
      ? ([EditorsMobile, 'EditorsMobile'] as const)
      : ([EditorsDesktop, 'EditorsDesktop'] as const);

    await getPageDownloader(pageDownloaderParams[0], pageDownloaderParams[1]);
  },
});
