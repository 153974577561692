import loadable from '@loadable/component';
import React from 'react';

import { fetchCurrenciesRates } from 'common/redux/commonData/currencies';
import {
  selectCurrencyByCharCode,
  selectCurrencyRates,
} from 'common/redux/commonData/currencies/selectors';
import { fetchBanksRates } from 'common/redux/commonData/widgets/banksRatesWidget/asyncs';
import {
  BANKS_RATES_CURRENCY_ACTION_BUY,
  BANKS_RATES_CURRENCY_ACTION_SELL,
} from 'common/redux/commonData/widgets/banksRatesWidget/typings';
import { resetDisabledPeriod } from 'common/redux/commonData/widgets/chartWidget';
import { loadDataForManyCourseChart } from 'common/redux/commonData/widgets/chartWidget/asyncs';
import { CURRENCY_SOURCES } from 'common/redux/commonData/widgets/exchangeRatesWidget/typings';
import {
  setCurrencies,
  setConverterPuids,
  setIsLoading,
} from 'common/redux/pages/converter';
import { appendPageDownloader } from 'common/routes/pageLoadable';
import { CURRENCY_CHAR_CODE } from 'config/constants/finance';
import { PAGE_TYPE } from 'config/constants/routerName';
import { IAppRoute, PromiseListType } from 'typings/AppRoute';
import { converterCrossCourse, getFixedValue } from 'utils/finance';

import { getFinancePromiseList } from '../getFinancePromiseList';

const ConverterDesktop = loadable(() => import('desktop/pages/Converter'));
const ConverterMobile = loadable(() => import('mobile/pages/Converter'));

type ConverterMatchRoute = {
  path?: string;
};

/**
 * Роут конвертера валют.
 * https://finance.rambler.ru/calculators/converter/
 * https://finance.rambler.ru/calculators/converter/1-EUR-USD/
 */
export const converterRoutes = (
  isMobile: Runtime['settings']['isMobile'],
): IAppRoute<ConverterMatchRoute> => ({
  name: PAGE_TYPE.converter,
  exact: true,
  path: '/calculators/converter/:path?/',
  render: isMobile ? () => <ConverterMobile /> : () => <ConverterDesktop />,
  fetchData: async ({ dispatch, getState }, { params: { path } }) => {
    dispatch(setConverterPuids());
    dispatch(setIsLoading(true));

    const promiseList: PromiseListType = [
      dispatch(fetchCurrenciesRates()),
      dispatch(
        fetchBanksRates({
          charCode: CURRENCY_CHAR_CODE.USD,
          sort: BANKS_RATES_CURRENCY_ACTION_BUY.USD,
        }),
      ),
      ...getFinancePromiseList(dispatch),
    ];

    if (!path) {
      promiseList.push(
        dispatch(
          fetchBanksRates({
            charCode: CURRENCY_CHAR_CODE.USD,
            sort: BANKS_RATES_CURRENCY_ACTION_SELL.USD,
          }),
        ),
      );
    }

    await Promise.all(
      appendPageDownloader({
        promiseList,
        Component: isMobile ? ConverterMobile : ConverterDesktop,
        key: Object.keys({ ConverterDesktop })[0],
      }),
    );

    dispatch(setIsLoading(false));

    if (!path) {
      // Сбрасываем стейт для корректного отображения значений формы при SPA переходах между страницами
      dispatch(
        setCurrencies({
          amount: undefined,
          firstCurrency: CURRENCY_CHAR_CODE.RUB,
          secondCurrency: CURRENCY_CHAR_CODE.USD,
          crossCourse: undefined,
          fixedValue: undefined,
          firstCurrencyConverter: CURRENCY_CHAR_CODE.RUB,
          secondCurrencyConverter: CURRENCY_CHAR_CODE.USD,
        }),
      );

      return;
    }

    const splittedPath = path.split('-');

    const amount = Number(splittedPath[0]);
    const firstCurrency = splittedPath[1] as CURRENCY_CHAR_CODE;
    const secondCurrency = splittedPath[2] as CURRENCY_CHAR_CODE;

    if (!amount || !firstCurrency || !secondCurrency) return;

    const firstCurrencyData =
      selectCurrencyByCharCode(firstCurrency)(getState());
    const secondCurrencyData =
      selectCurrencyByCharCode(secondCurrency)(getState());
    const firstCurrencyRates = selectCurrencyRates(firstCurrencyData?.id)(
      getState(),
    );
    const secondCurrencyRates = selectCurrencyRates(secondCurrencyData?.id)(
      getState(),
    );

    const crossCourse = converterCrossCourse(
      {
        nominal: firstCurrencyData?.nominal,
        rate: firstCurrencyRates?.[0]?.rate,
      },
      {
        nominal: secondCurrencyData?.nominal,
        rate: secondCurrencyRates?.[0]?.rate,
      },
    );

    const fixedValue = getFixedValue({ value: crossCourse * amount });

    dispatch(
      setCurrencies({
        amount,
        firstCurrency,
        secondCurrency,
        crossCourse,
        fixedValue,
      }),
    );

    dispatch(setIsLoading(true));
    dispatch(resetDisabledPeriod());

    await Promise.all([
      dispatch(
        loadDataForManyCourseChart({
          currencyCharCode: `${firstCurrency},${secondCurrency}`,
          source: CURRENCY_SOURCES.Centrobank,
        }),
      ),
    ]);

    dispatch(setIsLoading(false));
  },
});
