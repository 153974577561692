import { BannersDataType } from 'typings/Config';

import { PUIDS } from '../common';

import Banners from './banners';

export const auto: BannersDataType = {
  desktop: {
    BEGUN_PAD_ID: 455549814,
    banners: {
      [Banners.Topline]: {
        display: 'ssp',
        begun: 542288985,
      },
      [Banners.Billboard]: {
        display: 'ssp',
        begun: 455549824,
      },
      [Banners.BillboardCommercial1]: {
        display: 'sspScroll',
        begun: 579291216,
      },
      [Banners.BillboardCommercial2]: {
        display: 'sspScroll',
        begun: 579291218,
      },
      [Banners.BillboardCommercial3]: {
        display: 'sspScroll',
        begun: 579291220,
      },
      [Banners.Parallax]: {
        display: 'sspScroll',
        begun: 455549850,
      },
      [Banners['240x400']]: {
        display: 'ssp',
        begun: 455549826,
      },
      [Banners['240x400_2']]: {
        display: 'sspScroll',
        begun: 455549834,
      },
      [Banners.Context]: {
        // native 1
        display: 'ssp',
        data: {
          [PUIDS.puid61]: '165',
        },
        begun: 455549842,
      },
      [Banners.Native]: {
        // native 2
        display: 'sspScroll',
        data: {
          [PUIDS.puid61]: '166',
        },
        begun: 455549844,
      },
      [Banners.Superfooter]: {
        display: 'sspScroll',
        begun: 455549874,
      },
      [Banners.Nativefooter]: {
        // native footer
        display: 'sspScroll',
        data: {
          [PUIDS.puid61]: '354',
        },
        clusterFeedReadAlso: {
          [PUIDS.puid61]: '846',
        },
        dir1: {
          [PUIDS.puid61]: '844',
        },
        begun: 455549876,
      },
      [Banners['100x70']]: {
        display: 'sspScroll',
        begun: 455549888,
      },
      [Banners.Sponsored]: {
        display: 'ssp',
        begun: 455549878,
      },
      [Banners.Adcenter]: {
        display: 'sspScroll',
        begun: 455549836,
      },
      [Banners.Inread]: {
        display: 'ssp',
        begun: 455549838,
      },
      [Banners.NativePartner]: {
        display: 'sspScroll',
        begun: 473298088,
        dir1: {
          [PUIDS.puid61]: '845',
        },
      },
      [Banners.Ingallery]: {
        display: 'reloadssp',
        begun: 530718609,
      },
      [Banners.NativeInRecs]: {
        display: 'sspScroll',
        begun: 579349059,
      },
      [Banners.Paid]: {
        display: 'sspScroll',
        begun: 579387006,
      },
      [Banners.PromoWidget]: {
        data: {
          puid6: 'AUTORAMBLERRU_MAIN',
          puid18: 'AUTORAMBLERRU_MAIN_MAIN',
        },
        display: 'ssp',
        begun: 579498266,
      },
      [Banners.ShowUp]: {
        display: 'ssp',
        begun: 579503438,
      },
    },
  },
  mobile: {
    BEGUN_PAD_ID: 460649690,
    banners: {
      [Banners.TopBanner]: {
        begun: 460649728,
        ampBegun: 491775623,
        p1: 'bzmem',
      },
      [Banners.Rich]: {
        begun: 460649788,
        ampBegun: 579334547,
      },
      [Banners.Fullscreen]: {
        begun: 460649786,
      },
      [Banners.Listing1]: {
        begun: 460649732,
      },
      [Banners.Listing2]: {
        begun: 460649734,
      },
      [Banners.Listing3]: {
        begun: 460649736,
      },
      [Banners.Listing4]: {
        begun: 460649742,
      },
      [Banners.Content1]: {
        begun: 460649768,
        ampBegun: 472454052,
        p1: 'bymhu',
      },
      [Banners.Content2]: {
        begun: 460649770,
        ampBegun: 472454074,
        p1: 'bymhv',
      },
      [Banners.Content3]: {
        begun: 460649772,
        ampBegun: 472454164,
        p1: 'bymhw',
      },
      [Banners.Content4]: {
        begun: 460649774,
        ampBegun: 472454326,
        p1: 'bymhx',
      },
      [Banners.Spec]: {
        begun: 460649812,
      },
      [Banners.ContentSpec]: {
        begun: 460649816,
      },
      [Banners.Inpage]: {
        begun: 460649776,
      },
      [Banners.Sponsored]: {
        begun: 460649810,
      },
      [Banners.Footer]: {
        begun: 460649778,
        ampBegun: 472454354,
        p1: 'bymhy',
      },
      [Banners.Paid]: {
        begun: 579387008,
      },
      [Banners.PromoWidget]: {
        data: {
          puid6: 'AUTORAMBLERRU_MAIN',
          puid18: 'AUTORAMBLERRU_MAIN_MAIN',
        },
        display: 'ssp',
        begun: 579498270,
      },
    },
  },
};
