import { AUTOTAG_TYPE } from 'config/constants/cluster';

import { PAGE_TYPE } from './routerName';
import { FORMAT_TOPIC_ALIASES, TOPIC_ALIAS_STARLIFE } from './topics';

export const ROOT_ID_NAME = 'app';

export const EDITOR_HEADER = 'О проекте';

export enum PUIDS {
  puid6 = 'puid6',
  puid15 = 'puid15',
  puid18 = 'puid18',
  puid31 = 'puid31',
  puid41 = 'puid41',
  puid48 = 'puid48',
  puid54 = 'puid54',
  puid59 = 'puid59',
  puid61 = 'puid61',
  puid62 = 'puid62',
}

/**
 * Объект пуидов
 */
export type PuidsType = {
  [PUIDS.puid6]?: string;
  [PUIDS.puid15]?: string;
  [PUIDS.puid18]?: string;
  [PUIDS.puid31]?: string;
  [PUIDS.puid41]?: string;
  [PUIDS.puid48]?: string;
  // Говорит о том, что на данной странице не должно быть брендинга 'commercial-branding'
  [PUIDS.puid54]?: string;
  [PUIDS.puid59]?: string;
  [PUIDS.puid61]?: string;
  [PUIDS.puid62]?: string;
};

// Пуиды для рекламного блока Native в списочной версии
export const NATIVE_LIST_VERSION_PUIDS: PuidsType = {
  puid54: '13',
};

// Пуиды для рекламного блока Native в новой версии редизайна
export const NATIVE_PUIDS: PuidsType = {
  puid54: '1',
};

/**
 * Функция модификации настроек пуидов.
 * @param puids - объект пуидов;
 * @param newConfig - объект пуидов, расширяющий первый объект.
 */
export const modifyPuids = (puids: PuidsType, newConfig: PuidsType) => ({
  ...puids,
  ...newConfig,
});

export const COMMON_REDIS_RETRY_INTERVAL_IN_MS = 5000;

/**
 * Функция-заглушка
 */
export const defaultCallback = () => {};

/**
 * Функция получения флага, что страница из списка списочных страниц.
 * @param pageName - имя страницы.
 */
export const getIsListPage = (pageName: PAGE_TYPE) =>
  [
    PAGE_TYPE.autotag,
    PAGE_TYPE.tag,
    PAGE_TYPE.clusterItems,
    PAGE_TYPE.date,
    PAGE_TYPE.latest,
    PAGE_TYPE.theme,
  ].includes(pageName);

/**
 * Функция получения флага страницы персоны.
 * @param pageName - имя страницы;
 * @param autotagType - тип автотега.
 */
export const getIsPersonPage = (
  pageName: PAGE_TYPE,
  autotagType: AUTOTAG_TYPE | null,
) => getIsListPage(pageName) && autotagType === AUTOTAG_TYPE.person;

/**
 * Функция получения флага, что страница редактора или эксперта.
 * @param pageName - имя страницы.
 */
export const getIsEditorOrExpertPage = (pageName: PAGE_TYPE) =>
  [PAGE_TYPE.editor, PAGE_TYPE.expert].includes(pageName);

/**
 * Функция получения флага, что это страница путеводителя.
 */
export const checkIsGuidePage = (pageName: PAGE_TYPE) =>
  pageName === PAGE_TYPE.guide;

/**
 * Функция получения флага форматного топика.
 * @param topicAlias - алиас топика.
 */
export const getIsFormatTopic = (topicAlias: TopicType['alias']) =>
  FORMAT_TOPIC_ALIASES.includes(topicAlias);

/**
 * Функция получения флага топика Шоу-бизнес.
 * @param topicAlias - алиас топика.
 */
export const getIsStarlifeTopic = (
  topicAlias: TopicType['alias'] | undefined,
) => TOPIC_ALIAS_STARLIFE === topicAlias;
