import loadable from '@loadable/component';
import React from 'react';

import { setLatestPuids } from 'common/redux/pages/latest';
import { fetchLatestClusters } from 'common/redux/pages/latest/asyncs';
import { selectProjectAlias } from 'common/redux/runtime/selectors';
import { appendPageDownloader } from 'common/routes/pageLoadable';
import { PAGE_TYPE } from 'config/constants/routerName';
import { IAppRoute, PromiseListType } from 'typings/AppRoute';

const LatestDesktop = loadable(() => import('desktop/pages/Latest'));
const LatestMobile = loadable(() => import('mobile/pages/Latest'));

/**
 * Роут последних новостей.
 * https://news.rambler.ru/latest/
 */
export const latestRoutes = (
  isMobile: Runtime['settings']['isMobile'],
): IAppRoute => ({
  name: PAGE_TYPE.latest,
  exact: true,
  path: '/latest/',
  render: isMobile ? () => <LatestMobile /> : () => <LatestDesktop />,
  fetchData: async ({ dispatch, getState }, _routerMatch, queryParams) => {
    const page = Number(queryParams?.page || 1);

    const projectAlias = selectProjectAlias(getState());

    dispatch(setLatestPuids(projectAlias));

    const promiseList: PromiseListType = [
      dispatch(fetchLatestClusters({ page })),
    ];

    await Promise.all(
      appendPageDownloader({
        promiseList,
        Component: isMobile ? LatestMobile : LatestDesktop,
        key: Object.keys({ LatestDesktop })[0],
      }),
    );
  },
});
