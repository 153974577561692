import { BannersDataType } from 'typings/Config';

import { PUIDS } from '../common';

import Banners from './banners';

export const sport: BannersDataType = {
  desktop: {
    BEGUN_PAD_ID: 448514910,
    banners: {
      [Banners.Topline]: {
        display: 'ssp',
        begun: 542288841,
      },
      [Banners.Billboard]: {
        display: 'ssp',
        begun: 448516617,
      },
      [Banners.BillboardCommercial1]: {
        display: 'sspScroll',
        begun: 579291216,
      },
      [Banners.BillboardCommercial2]: {
        display: 'sspScroll',
        begun: 579291218,
      },
      [Banners.BillboardCommercial3]: {
        display: 'sspScroll',
        begun: 579291220,
      },
      [Banners.Parallax]: {
        display: 'sspScroll',
        begun: 448729150,
      },
      [Banners['240x400']]: {
        display: 'ssp',
        begun: 448516611,
      },
      [Banners['240x400_2']]: {
        display: 'sspScroll',
        begun: 448516613,
      },
      [Banners.Context]: {
        // native 1
        display: 'ssp',
        data: {
          [PUIDS.puid61]: '171',
        },
        begun: 448516625,
      },
      [Banners.Native]: {
        // native 2
        display: 'sspScroll',
        data: {
          [PUIDS.puid61]: '172',
        },
        begun: 448516627,
      },
      [Banners.Superfooter]: {
        display: 'sspScroll',
        begun: 448516609,
      },
      [Banners.Nativefooter]: {
        // native footer
        display: 'sspScroll',
        data: {
          [PUIDS.puid61]: '355',
        },
        clusterFeedReadAlso: {
          [PUIDS.puid61]: '843',
        },
        dir1: {
          [PUIDS.puid61]: '788',
        },
        begun: 448517679,
      },
      [Banners['100x70']]: {
        display: 'sspScroll',
        begun: 448516619,
      },
      [Banners.Sponsored]: {
        display: 'ssp',
        begun: 448729168,
      },
      [Banners.Adcenter]: {
        display: 'sspScroll',
        begun: 448729086,
      },
      [Banners.Inread]: {
        display: 'ssp',
        begun: 448516623,
      },
      [Banners.NativePartner]: {
        // Native3
        display: 'sspScroll',
        begun: 473298116,
        dir1: {
          [PUIDS.puid61]: '842',
        },
      },
      [Banners.Ingallery]: {
        display: 'reloadssp',
        begun: 530717921,
      },
      [Banners.Fonbet]: {
        display: 'ssp',
        begun: 522973415,
      },
      [Banners.NativeInRecs]: {
        display: 'sspScroll',
        begun: 579349063,
      },
      [Banners.Paid]: {
        display: 'sspScroll',
        begun: 579387032,
      },
      [Banners.PromoWidget]: {
        data: {
          puid6: 'SPORTRAMBLERRU_MAIN',
          puid18: 'SPORTRAMBLERRU_MAIN_MAIN',
        },
        display: 'ssp',
        begun: 579498284,
      },
      [Banners.ShowUp]: {
        display: 'ssp',
        begun: 579503442,
      },
    },
  },
  mobile: {
    BEGUN_PAD_ID: 460428472,
    banners: {
      [Banners.TopBanner]: {
        begun: 460428556,
        ampBegun: 491776069,
        p1: 'bzmes',
      },
      [Banners.Rich]: {
        begun: 460428716,
        ampBegun: 579334543,
      },
      [Banners.Fullscreen]: {
        begun: 460428654,
      },
      [Banners.Listing1]: {
        begun: 460428676,
      },
      [Banners.Listing2]: {
        begun: 460428690,
      },
      [Banners.Listing3]: {
        begun: 460428692,
      },
      [Banners.Listing4]: {
        begun: 460428694,
      },
      [Banners.Content1]: {
        begun: 460428608,
        ampBegun: 472448990,
        p1: 'bymhi',
      },
      [Banners.Content2]: {
        begun: 460428614,
        ampBegun: 472448992,
        p1: 'bymhj',
      },
      [Banners.Content3]: {
        begun: 460428618,
        ampBegun: 472448994,
        p1: 'bymhl',
      },
      [Banners.Content4]: {
        begun: 460428626,
        ampBegun: 472449338,
        p1: 'bymhm',
      },
      [Banners.Spec]: {
        begun: 460428700,
      },
      [Banners.ContentSpec]: {
        begun: 460428636,
      },
      [Banners.Inpage]: {
        begun: 460428602,
      },
      [Banners.Sponsored]: {
        begun: 460428718,
      },
      [Banners.Footer]: {
        begun: 460428638,
        ampBegun: 472449342,
        p1: 'bymhn',
      },
      [Banners.Fonbet]: {
        begun: 522979309,
      },
      [Banners.Paid]: {
        begun: 579387052,
      },
      [Banners.PromoWidget]: {
        data: {
          puid6: 'SPORTRAMBLERRU_MAIN',
          puid18: 'SPORTRAMBLERRU_MAIN_MAIN',
        },
        display: 'ssp',
        begun: 579498286,
      },
    },
  },
};
