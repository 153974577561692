import {
  EXTRA_PROJECT_ALIASES,
  NEWS_PROJECT_ALIASES,
  PROJECT_ALIASES,
  PROJECT_ALIAS_BY_ID,
  PROJECT_IDS,
} from 'config/constants/projects/constants';
import { PORT } from 'config/profiles/local';

export enum PROXY_DOMAINS {
  Avtorambler = 'avtorambler',
  Autorambler = 'autorambler',
  Rns = 'finance-rambler',
  Redigo = 'redigo',
  RamblerUA = 'news.rambler.ua',
  RamblerUALocal = 'newsua',
}

// @PROXY_CHECK - править тут
const PROXY_TO_PROJECT_ALIASES_MAP: Record<
  PROXY_DOMAINS,
  NEWS_PROJECT_ALIASES
> = {
  [PROXY_DOMAINS.Avtorambler]: NEWS_PROJECT_ALIASES.Auto,
  [PROXY_DOMAINS.Autorambler]: NEWS_PROJECT_ALIASES.Auto,
  [PROXY_DOMAINS.RamblerUA]: NEWS_PROJECT_ALIASES.News,
  [PROXY_DOMAINS.RamblerUALocal]: NEWS_PROJECT_ALIASES.News,
  [PROXY_DOMAINS.Redigo]: NEWS_PROJECT_ALIASES.Travel,
  [PROXY_DOMAINS.Rns]: NEWS_PROJECT_ALIASES.Finance,
};

const DOMAINS_LIST = Object.values(PROXY_DOMAINS);

/**
 * Функция, проверяющая, относится ли переданная строка с урлом/доменом к списку проксируемых.
 * @param url - строка для проверки.
 */
export const checkProxyDomainInUrl = (url: string) =>
  DOMAINS_LIST.find((domain) => url.includes(domain));

/**
 * Функция, приводящая постфикс урла (то есть оставшуюся часть урла, без алиаса),
 * к корректному виду, если вертикаль оригинального урла не совпадает с вертикалью,
 * которая сейчас проксируется.
 *
 * То есть, пример:
 * Дано:
 * Урл, на котором находится юзер - finance-rambler
 * Урл, на который ведет ссылка - news.rambler.ru
 *
 * Если урл не подменить, то ссылка будет вести на finance-rambler.ru/, что не совсем корректно,
 * но так как постфикс уже является finance-rambler.ru, то его нужно заменить на корректный rambler.ru
 * @param projectAlias - англоязычное наименование проекта.
 */
export const getCorrectPostfix = (
  postfix: string,
  projectAlias: NEWS_PROJECT_ALIASES | EXTRA_PROJECT_ALIASES,
) => {
  const proxyDomain = checkProxyDomainInUrl(postfix);

  /**
   * Если постфикс вообще не относится к прокси,
   *   просто отдаем обычный постфикс с алиасом.
   */
  if (!proxyDomain) return `${projectAlias}${postfix}`;

  /**
   * Если постфикс относится к той вертикали, которую проксирует,
   *  то просто отдаем постфикс без модификаций, ведь все корректно
   */
  if (PROXY_TO_PROJECT_ALIASES_MAP[proxyDomain] === projectAlias)
    return postfix;

  /**
   * Если же постфикс нужно модифицировать и мы на стейдже/локалке
   *  то подменяем лишь последний уровень на корректную вертикаль.
   *
   * Внимание, это не актуально для news.rambler.ua, ибо для него нет ни стейджа, ни локалки.
   */
  if (postfix.includes('stage') || postfix.includes('eve'))
    return postfix.replace(proxyDomain, projectAlias);

  /**
   * Если это продовый постфикс, и его вертикаль не совпадает с нормальной вертикалью,
   *  то генерируем домен с нуля.
   */
  return `${projectAlias}.rambler.ru`;
};

/**
 * Ядро генератора домена для id проекта
 * @param domainConfig - данные о домене
 * @param projectAlias - строчка с алиасом вертикали
 */
export const domainGeneratorCore = (
  domainConfig: Runtime['domainConfig'],
  projectAlias: PROJECT_ALIASES,
) => {
  const { port, protocol, prefix, postfix } = domainConfig;

  const strPort = port ? `:${port}` : '';

  const url = getCorrectPostfix(postfix, projectAlias);

  return `${protocol}://${prefix}${url}${strPort}`;
};

type domainGeneratorType = (
  runtime: Runtime['domainConfig'],
  projectID: PROJECT_IDS | null,
) => string;

/**
 * Генератор домена для переданного ID проекта
 * @param domainConfig - данные о домене проекта
 * @param projectID - ID проекта, для которого генерировать домен
 */
export const domainGenerator: domainGeneratorType = (
  domainConfig,
  projectID,
) =>
  projectID
    ? domainGeneratorCore(domainConfig, PROJECT_ALIAS_BY_ID[projectID])
    : '';

type GenerateClusterUrlType = {
  domainConfig: Runtime['domainConfig'];
  clusterId: CardData['id'] | null | undefined;
  normalizedTitle: string | undefined;
  topic: { project_id: PROJECT_IDS; alias: string } | undefined;
  addDomain?: boolean;
};

/**
 * Генератор урла на страницу новости
 * Использует типы из стора на redux-toolkit
 * @param params.domainConfig - данные о домене проекта
 * @param params.clusterId - id текущего кластера
 * @param params.normalizedTitle - чпу кластера
 * @param params.topic - объект данных о топике кластера
 * @param params.addDomain - генерация урла с доменом или без
 */
export function generateClusterUrl({
  domainConfig,
  normalizedTitle,
  clusterId,
  topic,
  addDomain = true,
}: GenerateClusterUrlType) {
  if (!topic?.alias || !clusterId) return '';

  const { project_id: projectId, alias } = topic;
  const normalize = normalizedTitle ? `-${normalizedTitle}` : '';

  if (addDomain && projectId) {
    const domain = domainGenerator(domainConfig, projectId);

    return `${domain}/${alias}/${clusterId}${normalize}/`;
  }

  return `/${alias}/${clusterId}${normalize}/`;
}

/**
 * Генерация абсолютного пути с подстановкой домена в начало урла
 * @param domainConfig - данные о домене проекта
 * @param url URL, можеть быть `string`
 */
export function absoluteUrl(
  domainConfig: Runtime['domainConfig'],
  projectId: ProjectType['id'],
  url: string,
) {
  const domain = domainGenerator(domainConfig, projectId);

  return `${domain}${url}`;
}

export const swapUrlToProxyDomain = ({
  url,
  domainConfig,
  projectId,
}: {
  url: string;
  domainConfig: Runtime['domainConfig'];
  projectId: Runtime['project']['id'];
}) => {
  if (checkProxyDomainInUrl(url)) return url;

  try {
    const parsedUrl = new URL(url);
    const domain = new URL(domainGenerator(domainConfig, projectId));

    parsedUrl.hostname = domain.hostname;

    if (__DEV__) {
      parsedUrl.port = `${PORT}`;
    }

    return parsedUrl.toString();
  } catch {
    return url;
  }
};
