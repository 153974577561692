import {
  selectHumanCenteredStrategyValueFromSplits,
  selectIsMobile,
  selectSplits,
} from 'common/redux/runtime/selectors';
import { APP_TYPE } from 'config/constants/devices';
import { NEWS_PROJECT_ALIASES } from 'config/constants/projects/constants';

import {
  mediaTop100HumanCenteredStrategyDesk,
  mediaTop100HumanCenteredStrategyMob,
} from './constants';

/**
 * Генерирует строку всех возможных аттрибутов для топ100 медиа
 */
const getAllTop100MediaAttributes = () =>
  Object.values(NEWS_PROJECT_ALIASES).reduce((attributes, projectAlias) => {
    attributes.push(`${projectAlias}_media-desktop`);
    attributes.push(`${projectAlias}_media-mobile`);

    return attributes;
  }, [] as string[]);

/**
 * Функция для получения полного массива аттрибутов топ100.
 */
export const getTop100Dataset = () => {
  const top100MediaAttributes = getAllTop100MediaAttributes();

  return [
    'blocks',
    ...top100MediaAttributes,
    'logo',
    'cerber-topline',
    'cerber-topline-mob',
    'cerber-search',
    'cerber-footer',
    'cerber-footer-mob',
    'cerber',
    'mc',
  ];
};

/**
 * Функция для получения полного массива аттрибутов топ100.
 */
export const getTop100Splits = (state: IAppState) => {
  const isMobile = selectIsMobile(state);
  const splits = selectSplits(state);

  const splitsStr = '';
  const splitsPlatform = isMobile ? APP_TYPE.mobile : APP_TYPE.desktop;

  const humanCenteredStrategyDesk =
    mediaTop100HumanCenteredStrategyDesk[
      selectHumanCenteredStrategyValueFromSplits(splits)
    ];
  const humanCenteredStrategyMob =
    mediaTop100HumanCenteredStrategyMob[
      selectHumanCenteredStrategyValueFromSplits(splits)
    ];

  return [
    splitsPlatform,
    isMobile ? humanCenteredStrategyMob : humanCenteredStrategyDesk,
    splitsStr,
  ];
};
