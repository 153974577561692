import { HUMAN_CENTERED_STRATEGY_SPLIT_VALUES } from 'config/constants/splits';

// NEWS-11896
export const mediaTop100HumanCenteredStrategyDesk = {
  [HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.default]:
    'humanCenteredStrategy_web_default',
  [HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.on]: 'humanCenteredStrategy_web_on',
  [HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.off]: 'humanCenteredStrategy_web_off',
};

// NEWS-11896
export const mediaTop100HumanCenteredStrategyMob = {
  [HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.default]:
    'humanCenteredStrategy_mob_default',
  [HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.on]: 'humanCenteredStrategy_mob_on',
  [HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.off]: 'humanCenteredStrategy_mob_off',
};
